import request from "@/services/request";
export function selectFromById(params){
    return request({
        url:'/jput/form/selectFromById',
        method:'get',
        params
    })
}
export function saveOrUpdateForms(data){
    return request({
        url:'/jput/form/saveOrUpdateForms',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function pilotSaveOrUpdateTimesheet(data){
    return request({
        url:'/jput/form/pilot/saveOrUpdateTimesheet',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function pilotReportEquipment(params){
    return request({
        url:'/jput/form/pilot/reportEquipment',
        method:'get',
        params
    })
}
export function pilotSaveReportEquipment(data){
    return request({
        url:'/jput/form/pilot/saveReportEquipment',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function getEquipmentReports(params) {
    return request({
        url:'/jput/form/pilot/getReportEquipmentList',
        method:'get',
        params
    })
}
export function pilotResolveReportEquipment(data) {
    return request({
        url:'/jput/form/pilot/resolveReportEquipment',
        method:'post',
        data:JSON.stringify(data),
    })
} 
export function getPilotList(params) {
    return request({
        url:'/jput/pilot/getPilotsInfo',
        method:'get',
        params
    })
}
export function savePilot(data) {
    return request({
        url:'/jput/pilot/savePilot',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function deletePilot(id) {
    return request({
        url:'/jput/pilot/deletePilot/' + id,
        method:'put',
    })
}
export function getTugBoatList(params) {
    return request({
        url:'/jput/pilot/getTugBoatsInfo',
        method:'get',
        params
    })
}
export function saveTugBoat(data) {
    return request({
        url:'/jput/pilot/saveTugBoat',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function deleteTugBoat(id) {
    return request({
        url:'/jput/pilot/deleteTugBoat/' + id,
        method:'put',
    })
}
export function getFormTugBoat(params) {
    return request({
        url:'/jput/form/pilot/tugBoat',
        method:'get',
        params
    })
}
export function saveFormTugBoat(data) {
    return request({
        url:'/jput/form/pilot/saveTugBoat',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function savePilotRemarks(data) {
    return request({
        url:'/jput/pilot/savePilotRemarks',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function signForm(data){
    return request({
        url:'/jput/form/signForm',
        method:'post',
        data
    })

}
export function getSystemEmail(params) {
    return request({
        url:'/sysEmail/list',
        method:'get',
        params
    })
}
export function saveSystemEmail(data) {
    return request({
        url:'/sysEmail/save',
        method:'post',
        data:JSON.stringify(data),
    })
}
export function removeSystemEmail(id) {
    return request({
        url:'/sysEmail/remove/' + id,
        method:'delete',
    })
}
export function getMaintenanceDetails(params) {
    return request({
        url:'/sysSetting/getMaintenanceDetails',
        method:'get',
        params
    })
}
export function sendScheduledEmail(data){
    return request({
        url:'/sysEmail/sendScheduledEmail',
        method:'post',
        data
    })
}